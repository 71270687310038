/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Akram's Portfolio",
  description:
    "My self Md Akram Khan, I am a Full Stack Web Developer Skilled in ReactJS, Django, Java, JavaScript, Python and many more. Feel free to contact me anytime.",
  og: {
    title: "Md Akram Khan Portfolio",
    type: "website",
    url: "http://mdakram09.netlify.app/",
  },
};

//Home Page
const greeting = {
  title: "Md Akram Khan",
  logo_name: "Md Akram Khan",
  nickname: "ReactJS Developer",
  subTitle:
    "My self Md Akram Khan, I am a Full Stack Web Developer Skilled in ReactJS, Django, Java, JavaScript, Python and many more. Feel free to contact me anytime.",
  resumeLink:
    "https://drive.google.com/file/d/1Ockzo-i0Xg4uM9Msk5LdMNGrtJ1Vav4J/view?usp=sharing",
  portfolio_repository: "https://github.com/mdakram09/mdakram09",
  githubProfile: "https://github.com/mdakram09",
};

const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/ashutosh1919",
  // linkedin: "https://www.linkedin.com/in/ashutosh-hathidara-88710b138/",
  // gmail: "ashutoshhathidara98@gmail.com",
  // gitlab: "https://gitlab.com/ashutoshhathidara98",
  // facebook: "https://www.facebook.com/laymanbrother.19/",
  // twitter: "https://twitter.com/ashutosh_1919",
  // instagram: "https://www.instagram.com/layman_brother/"

  {
    name: "Github",
    link: "https://github.com/mdakram09",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/mdakram09/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  // {
  //   name: "YouTube",
  //   link: "https://www.youtube.com/channel/UC_amoXmmxSY9KusoDczDTXQ",
  //   fontAwesomeIcon: "fa-youtube", // Reference https://fontawesome.com/icons/youtube?style=brands
  //   backgroundColor: "#FF0000", // Reference https://simpleicons.org/?q=youtube
  // },
  {
    name: "Gmail",
    link: "mailto:iamakramkhan09@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  // {
  //   name: "Twitter",
  //   link: "https://twitter.com/ashutosh_1919",
  //   fontAwesomeIcon: "fa-twitter", // Reference https://fontawesome.com/icons/twitter?style=brands
  //   backgroundColor: "#1DA1F2", // Reference https://simpleicons.org/?q=twitter
  // },
  // {
  //   name: "Facebook",
  //   link: "https://www.facebook.com/laymanbrother.19/",
  //   fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
  //   backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  // },
  {
    name: "Instagram",
    link: "https://www.instagram.com/khan_akram07/",
    fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  },
];

const skills = {
  data: [
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building resposive Frontend websites using React-Redux",
        "⚡ Creating application backend in Node, Express, Django and Java",
        "⚡ Have Worked on React, Redux and Redux Saga on corporate level",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Bootstrap",
          fontAwesomeClassname: "simple-icons:bootstrap",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#663399",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "simple-icons:python",
          style: {
            color: "#2C8EBB",
          },
        },
        {
          skillName: "Django",
          fontAwesomeClassname: "simple-icons:django",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "Flask",
          fontAwesomeClassname: "simple-icons:flask",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "MySql",
          fontAwesomeClassname: "simple-icons:mysql",
          style: {
            color: "#02569B",
          },
        },
        {
          skillName: "Java",
          fontAwesomeClassname: "simple-icons:java",
          style: {
            color: "#02569B",
          },
        },
        {
          skillName: "C Language",
          fontAwesomeClassname: "simple-icons:c",
          style: {
            color: "#02569B",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#02569B",
          },
        },
        {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            color: "#02569B",
          },
        },
        {
          skillName: "Googling",
          fontAwesomeClassname: "simple-icons:google",
          style: {
            color: "#02569B",
          },
        },
      ],
    },
    // {
    //   title: "Data Science & AI",
    //   fileName: "DataScienceImg",
    //   skills: [
    //     "⚡ Developing highly scalable production ready models for various deeplearning and statistical use cases",
    //     "⚡ Experience of working with Computer Vision and NLP projects",
    //     "⚡ Complex quantitative modelling for dynamic forecasting and time series analysis",
    //   ],
    //   softwareSkills: [
    //     {
    //       skillName: "Tensorflow",
    //       fontAwesomeClassname: "logos-tensorflow",
    //       style: {
    //         backgroundColor: "transparent",
    //       },
    //     },
    //     {
    //       skillName: "Keras",
    //       fontAwesomeClassname: "simple-icons:keras",
    //       style: {
    //         backgroundColor: "white",
    //         color: "#D00000",
    //       },
    //     },
    //     {
    //       skillName: "PyTorch",
    //       fontAwesomeClassname: "logos-pytorch",
    //       style: {
    //         backgroundColor: "transparent",
    //       },
    //     },
    //     {
    //       skillName: "Python",
    //       fontAwesomeClassname: "ion-logo-python",
    //       style: {
    //         backgroundColor: "transparent",
    //         color: "#3776AB",
    //       },
    //     },
    //   ],
    // },
    {
      title: "UI/UX Design",
      fileName: "DesignImg",
      skills: [
        "⚡ Designing highly attractive user interface for mobile and web applications",
        "⚡ Customizing logo designs and building logos from scratch",
        "⚡ Creating the flow of application functionalities to optimize user experience",
      ],
      softwareSkills: [
        {
          skillName: "Adobe XD",
          fontAwesomeClassname: "simple-icons:adobexd",
          style: {
            color: "#FF2BC2",
          },
        },
        {
          skillName: "Figma",
          fontAwesomeClassname: "simple-icons:figma",
          style: {
            color: "#F24E1E",
          },
        },
        {
          skillName: "Adobe Illustrator",
          fontAwesomeClassname: "simple-icons:adobeillustrator",
          style: {
            color: "#FF7C00",
          },
        },
        {
          skillName: "Inkscape",
          fontAwesomeClassname: "simple-icons:inkscape",
          style: {
            color: "#000000",
          },
        },
      ],
    },
    {
      title: "DevOps",
      fileName: "CloudInfraImg",
      skills: ["⚡ Currently I am learning DevOps 😊"],
      softwareSkills: [
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "GitHub",
      iconifyClassname: "simple-icons:github",
      style: {
        color: "#000000",
      },
      profileLink: "https://github.com/mdakram09",
    },
    {
      siteName: "Leetcode",
      iconifyClassname: "simple-icons:leetcode",
      style: {
        color: "#5B4638",
      },
      profileLink: "https://leetcode.com/mdakram09/",
    },
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://www.hackerrank.com/iamakramkhan09",
    },
    {
      siteName: "Codechef",
      iconifyClassname: "simple-icons:codechef",
      style: {
        color: "#323754",
      },
      profileLink: "https://www.codechef.com/users/mdakram09",
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Guru Nanak Institute of Technology, Kolkata",
      subtitle: "B.Tech. in Computer Science and Engineering",
      logo_path: "GNIT_Kolkata_logo.png",
      alt_name:
        "B.Tech. in Computer Science and Engineering, Guru Nanak Institute of Technology, Kolkata",
      duration: "2018 - 2022",
      descriptions: [
        "⚡ I have studied basic software engineering subjects like Data Structures, Algorithms, DBMS, OS, etc.",
        "⚡ Apart from this, I have done courses on Full Stack Web Development, ReactJs, Django and Soft Skills.",
        "⚡ I was selected for Merit cum Means Scholarship which is given to top 10% of students in college. I have received award from respected director for consistently best performance in academics.",
      ],
      website_link: "https://gnit.ac.in/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "The Joy of Computing using Python",
      subtitle: "- NPTEL - IIT Ropar",
      logo_path: "7-2-python-logo-free-download-png.png",
      certificate_link:
        "https://drive.google.com/file/d/1KW8mOqXPo4DOh-OE5KvKRc1HqB88KMUs/view?usp=sharing",
      alt_name: "The Joy of Computing using Python NPTEL - IIT Ropar",
      color_code: "#8C151599",
    },
    {
      title: "Programming In Java",
      subtitle: "- NPTEL - IIT Kharagpur",
      logo_path: "85390-java-language-text-programming-logo-programmer.png",
      certificate_link:
        "https://drive.google.com/file/d/1Oh0tSj2OHVuwc8JRnNS5dqY_U1oMGfDv/view?usp=sharing",
      alt_name: "Programming In Java NPTEL - IIT Ropar",
      color_code: "#00000099",
    },
    {
      title: "Problem Solving Through Programming In C",
      subtitle: "- NPTEL - IIT Kharagpur",
      logo_path: "5a36954d40bea2.0735336615135266052652.png",
      certificate_link:
        "https://drive.google.com/file/d/1zAN-i03rxFUzltttTWj0n3nvtbojbWYO/view?usp=sharing",
      alt_name:
        "Problem Solving Through Programming In C NPTEL - IIT Kharagpur",
      color_code: "#0C9D5899",
    },
    {
      title: "Soft Skills",
      subtitle: "- NPTEL - IIT Roorkee",
      logo_path:
        "kisspng-instructor-led-training-learning-course-education-styled-layer-descriptor-5b1b7a2b0911e6.1200257615285274030372.png",
      certificate_link:
        "https://drive.google.com/file/d/1L9oHvfNCeL2-wEIG4dZEccRyTRZIf5oW/view?usp=sharing",
      alt_name: "Soft Skills NPTEL - IIT Roorkee",
      color_code: "#1F70C199",
    },
    {
      title: "Full-Stack Web Development with React Specialization",
      subtitle:
        "- Coursera - The Hong Kong University of Science and Technology",
      logo_path:
        "kisspng-react-javascript-angularjs-ionic-atom-5b154be6947457.3471941815281223426081.png",
      certificate_link:
        "https://coursera.org/share/8e5e3044f54a3f246119399c8fc050f1",
      alt_name:
        "Full-Stack Web Development with React Specialization - Coursera - The Hong Kong University of Science and Technology",
      color_code: "#D83B0199",
    },
    {
      title: "Python for Data Science",
      subtitle: "- iNeuron.ai",
      logo_path: "ineuron-logo.png",
      certificate_link:
        "https://academy.ineuron.ai/certificates/downloads/PY010326.pdf",
      alt_name: "Python for Data Science - iNeuron.ai",
      color_code: "#1F70C199",
    },
    {
      title: "Machine Learning Masters",
      subtitle: "- iNeuron.ai",
      logo_path: "ineuron-logo.png",
      certificate_link:
        "https://academy.ineuron.ai/certificates/downloads/ML004782.pdf",
      alt_name: "Machine Learning Masters - iNeuron.ai",
      color_code: "#1F70C199",
    },
    {
      title: "Stats for Data Science",
      subtitle: "- iNeuron.ai",
      logo_path: "ineuron-logo.png",
      certificate_link:
        "https://academy.ineuron.ai/certificates/downloads/ST001244.pdf",
      alt_name: "Stats for Data Science - iNeuron.ai",
      color_code: "#1F70C199",
    },
    {
      title: "JavaScript Algorithms and Data Structures",
      subtitle: "- FreeCodeCamp",
      logo_path: "javascript-39404.png",
      certificate_link:
        "https://freecodecamp.org/certification/mdakram09/javascript-algorithms-and-data-structures",
      alt_name: "JavaScript Algorithms and Data Structures - FreeCodeCamp",
      color_code: "#2A73CC",
    },
    {
      title: "Google IT Automation with Python Professional Certificate",
      subtitle: "- Coursera - Google",
      logo_path: "google_logo.png",
      certificate_link:
        "https://coursera.org/share/637a037cecd92210860d400b1d99aff4",
      alt_name: "GCP",
      color_code: "#4285F499",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Freelancing",
  description:
    "I have worked with many evolving startups as React Developer, Django Developer, Designer, Full Stack Developer and Data Analytics. I have also worked with some well established companies mostly as React Developer.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      experiences: [
        {
          title: "Associate Software Engineer-I",
          company: "HighRadius Technologies Private Ltd.",
          company_url: "https://www.highradius.com/",
          logo_path: "HighRadius-Trifecta-2020.svg",
          duration: "July 2022 - PRESENT",
          location: "Hyderabad, Telangana",
          description:
            "Developing scalable application using ReactJs with Redux and Sagas as Frontend, Owning the coding/design of a UI sub-module, Review the requirements and provide simple, workable solutions, Implement the critical parts of the module Design and document the sub-module working with the tech-lead, Ensure coding standards, proper design patterns, writing test cases and project management processes are followed, Work independently and proactively to deliver production-ready code with minimal guidance",
          color: "#0879bf",
        },
      ],
    },
    {
      title: "Internships",
      experiences: [
        {
          title: "ReactJS and NLP Intern for 1 Year",
          company: "HighRadius Technologies Private Ltd.",
          company_url: "https://www.highradius.com/",
          logo_path: "HighRadius-Trifecta-2020.svg",
          duration: "August 2021 - June 2022",
          location: "Remote",
          description:
            "Worked on a project called AI ENABLED B2B INVOICE MANAGEMENT SYSTEM, Worked on updating and maintaining company's credit application using ReactJS, Created an interactive dashboard using ReactJs Created Call Center and Banking Chatbot, Followed agile methods to manage development life cycle",
          color: "#ee3c26",
        },
        {
          title: "Data Analytics and Django Intern",
          company: "Ineuron Intelligence PVT LTDC",
          company_url: "https://ineuron.ai/",
          logo_path: "ineuron-logo.png",
          duration: "Nov 2020 - April 2021",
          location: "Remote",
          description:
            "Build a project called iNews in which I have worked on building text summarization ML model for news articles and news headline- generation, Integrating the ML model to a fully functional web dashboard with some news articles from various news websites by using web scraping and APIs Worked on building Twitter sentiment analysis ML model and integrated it with Web Dashboard",
          color: "#0071C5",
        },
      ],
    },
    {
      title: "Freelancing",
      experiences: [
        {
          title: "Full Stack Django Developer",
          company: "MrElite.in",
          company_url: "https://mrelite.in/",
          logo_path: "pwa.png",
          duration: "January 2022 - March 2022",
          location: "Remote",
          description:
            "Developed the whole website of MrElite.in using the Django framework. Worked along with the CEO and Founding members to full fill the Business Requirements.",
          color: "#4285F4",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools. My best experience is to create Full Stack Website projects and deploy them using cloud infrastructure.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description:
    "I have worked on and published a few research papers and publications of my own.",
  avatar_image_path: "projects_image.svg",
};

const AwardsAndachievementsHeader = {
  title: "Awards and Achievements",
  description:
    "I have got many awards and recognitions in my academics which are listed below.",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "p2.png",
    description:
      "I am available on almost every social media. You can message me, I will reply within 24 hours. I can help you with React, JavaScript, Python, Java, Django, MongoDB, MySql and Opensource Development.",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "For individual fundamental empowerment, I like to write powerful lessons that create impact on each of the reader individually to change the core of their character.",
    link: "https://blog.akramkhan.me/",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle:
      "11/12 MG Road Khardah, 24 Parganas(North), Kolkata-700117, West Bengal.",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://goo.gl/maps/j6LwzVqjbVhbBpEL9",
  },
  phoneSection: {
    title: "Phone Number",
    subtitle: "+91 7278629160",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  contactPageData,
  AwardsAndachievementsHeader,
};
